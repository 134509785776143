var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[(_vm.showBotaoRecomendacao)?_c('FormButton',{attrs:{"text":"Configurar cupom de recomendação","type":"default"},on:{"click":_vm.onOpenRecomendacao}}):_vm._e(),_c('FormButton',{attrs:{"text":"Novo cupom","type":"primario"},on:{"click":_vm.onNew}})],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"sortDesc":false,"sortBy":"name","search":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"tipo",fn:function(ref){
var col = ref.col;
return [(col === 1)?_c('Chip',{attrs:{"color":"blue","small":true,"text":"Indicação"}}):_vm._e(),(col === 2)?_c('Chip',{attrs:{"color":"orange","small":true,"text":"Recompensa"}}):_vm._e(),(col === 4)?_c('Chip',{attrs:{"color":"green","small":true,"text":"Cadastro"}}):_vm._e()]}},{key:"valordesconto",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.renderValorDesconto(row))+" ")]}},{key:"clientes",fn:function(ref){
var col = ref.col;
return [(!col || !col.length)?_c('Chip',{attrs:{"color":"cyan","text":"Qualquer cliente","small":true}}):_vm._e(),(col && col.length > 0)?_c('Chip',{attrs:{"color":"green","text":"Clientes selecionados","small":true}}):_vm._e()]}},{key:"datainicio",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.renderData(col))+" ")]}},{key:"datafim",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.tipo === 4 ? _vm.renderData(row.datafim) : 'Não expira')+" ")]}},{key:"qtd_uso",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.qtd_uso)+"/"+_vm._s(row.qtd_max_uso || '-')+" ")]}},{key:"nao_usar_com_politica",fn:function(ref){
var col = ref.col;
return [(col)?_c('Chip',{attrs:{"color":"cyan","text":"Não","small":true}}):_c('Chip',{attrs:{"color":"green","text":"Sim","small":true}})]}},{key:"obs",fn:function(ref){
var col = ref.col;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(col)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"col-observacao"},'div',attrs,false),on),[_vm._v(" "+_vm._s(col)+" ")])]}}:null],null,true)},[_c('span',{staticClass:"col-observacao-hover"},[_vm._v(_vm._s(col))])])]}},{key:"idcupomdesconto",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view",on:{"close":_vm.onClose}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }