<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            v-if="showBotaoRecomendacao"
            text="Configurar cupom de recomendação"
            type="default"
            @click="onOpenRecomendacao"
          />
          <FormButton text="Novo cupom" type="primario" @click="onNew" />
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :sortDesc="false"
        ref="datatable"
        sortBy="name"
        search
        @click="onClick"
      >
        <template v-slot:tipo="{ col }">
          <Chip v-if="col === 1" color="blue" :small="true" text="Indicação" />
          <Chip
            v-if="col === 2"
            color="orange"
            :small="true"
            text="Recompensa"
          />
          <Chip v-if="col === 4" color="green" :small="true" text="Cadastro" />
        </template>
        <template v-slot:valordesconto="{ row }">
          {{ renderValorDesconto(row) }}
        </template>
        <template v-slot:clientes="{ col }">
          <Chip
            v-if="!col || !col.length"
            color="cyan"
            text="Qualquer cliente"
            :small="true"
          />
          <Chip
            v-if="col && col.length > 0"
            color="green"
            text="Clientes selecionados"
            :small="true"
          />
        </template>
        <template v-slot:datainicio="{ col }">
          {{ renderData(col) }}
        </template>
        <template v-slot:datafim="{ row }">
          {{ row.tipo === 4 ? renderData(row.datafim) : 'Não expira' }}
        </template>
        <template v-slot:qtd_uso="{ row }">
          {{ row.qtd_uso }}/{{ row.qtd_max_uso || '-' }}
        </template>
        <template v-slot:nao_usar_com_politica="{ col }">
          <Chip v-if="col" color="cyan" text="Não" :small="true" />
          <Chip v-else color="green" text="Sim" :small="true" />
        </template>
        <template v-slot:obs="{ col }">
          <v-tooltip bottom>
            <template v-if="col" v-slot:activator="{ on, attrs }">
              <div class="col-observacao" v-bind="attrs" v-on="on">
                {{ col }}
              </div>
            </template>
            <span class="col-observacao-hover">{{ col }}</span>
          </v-tooltip>
        </template>
        <template v-slot:idcupomdesconto="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import { CUPOM_DESCONTO_REMOVE } from '@/store/actions/cupom'
import { SETOR_FOLHA_LOAD } from '@/store/actions/setor'
import { SEGMENTO_LIST } from '@/store/actions/segmento'
import Panel from '@/components/Panel'
import Chip from '@/components/Chip'
import { mapGetters } from 'vuex'
import * as moment from 'moment'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'CupomDescontoTable',
  components: {
    Chip,
    DataTable,
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules
  },
  data: () => ({
    menu: {},
    headers: [
      {
        text: 'Código do cupom',
        value: 'codigocupom'
      },
      { text: 'Origem', value: 'tipo' },
      {
        text: 'Desconto',
        value: 'valordesconto'
      },
      {
        text: 'Cliente',
        align: 'center',
        value: 'clientes'
      },
      {
        text: 'Data Início',
        align: 'center',
        value: 'datainicio'
      },
      {
        text: 'Data Fim',
        align: 'datafim',
        value: 'datafim'
      },
      {
        text: 'Usos',
        value: 'qtd_max_por_cliente',
        clickable: false
      },
      {
        text: 'Utilizados',
        value: 'qtd_uso',
        align: 'center',
        clickable: false
      },
      {
        text: 'Usa em promoções',
        value: 'nao_usar_com_politica',
        align: 'center',
        clickable: false
      },
      {
        text: 'Observações',
        value: 'obs',
        clickable: false
      },
      {
        text: 'Opções',
        value: 'idcupomdesconto',
        align: 'center',
        clickable: false
      }
    ],
    tableOptions: [{ text: 'Deletar', value: 'delete' }],
    TIPO_CUPOM_VALUE: {
      '%': '%',
      R$: '$',
      $: 'R$'
    },
    modulosForm: [
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP
    ]
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    endpoint() {
      return `/api/v2/cupom-desconto/listar/fornecedor-datatable/${this.getFornecedorId}`
    },
    showBotaoRecomendacao() {
      return this.getFornecedorTag['uses-share-coupom']
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  created() {
    this.$store.dispatch(SEGMENTO_LIST)
    this.$store.dispatch(SETOR_FOLHA_LOAD)
  },
  methods: {
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
      }
    },
    onNew() {
      this.$router.push('cupom-desconto/novo')
    },
    onOpenRecomendacao() {
      this.$router.push('cupom-desconto/config/recomendacao')
    },
    onClose() {
      this.$refs.datatable && this.$refs.datatable.reload()
    },
    onClick(item) {
      this.$router.push(`cupom-desconto/${item.idcupomdesconto}`)
    },
    onDelete(item) {
      const options = {
        text:
          'Lembrando que após o cupom ser removido ele não poderá ser utilizado novamente.',
        title: `Você confirma a exclusão deste cupom?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.$store
        .dispatch(CUPOM_DESCONTO_REMOVE, {
          idcupomdesconto: item.idcupomdesconto
        })
        .then(() => {
          this.$vueOnToast.pop('success', 'Cupom removido com sucesso')
          this.$refs.datatable.reload()
        })
    },
    renderValorDesconto(data) {
      return data.tipodesconto === '%'
        ? `${data.valordesconto}${data.tipodesconto}`
        : `${this.TIPO_CUPOM_VALUE[data.tipodesconto]}${this.formatReal(
            data.valordesconto
          )}`
    },
    renderData(col) {
      return col ? moment(col).format('DD/MM/YYYY') : ''
    },
    formatReal(v = '0') {
      return `${parseFloat(v).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`
    }
  }
}
</script>

<style lang="scss" scoped>
.col-observacao {
  text-overflow: ellipsis;
  height: 2em;
  width: 101px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.col-observacao-hover {
  text-overflow: ellipsis;
  max-width: 500px;
  display: block;
}
</style>
